.codeTxtBox {
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  margin-top: 8px;
  overflow: hidden;
  padding: 8px 16px;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0c0;
  border-radius: 1px;
  font-size: 13px;
  line-height: 18px;
  color: #333;
  box-shadow: none;
}
