.vertical-center {
    margin: 0;
    position: absolute;
    width: 100%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

img.bg-image {
  position: fixed;
  right: 0;
  bottom: 0;
  opacity: 0.3;
  width: 300px;
}
.login-background {
  color: white;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, #231815, #464342);
}
.login-background > * > * > * > * > h1 {
  color: white;
}
.login-card {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: #000 5px 5px 10px;
}
.login-form > * > .ant-form-item-label > label,
.login-form > * > * > * > * > * > .ant-radio-wrapper,
.login-form > * > * > * > * > * > * > * > * > * > * > .ant-checkbox-wrapper,
.ant-form-item-control-input {
  color: white;
}
.login-btn:hover {
  transition: none;
  background: rgb(136,60,146);
  background: linear-gradient(45deg, rgba(136,60,146,1) 0%, rgba(221,44,124,1) 50%, rgba(229,95,70,1) 100%);
  background-size: 600% 600%;
  animation: AnimeGrade 1.5s ease infinite;
}
.login-btn, .login-btn:active, .login-btn:focus {
  border: 0px;
  transition: none;
  background:  #595757
}

@keyframes AnimeGrade {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}