.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}
  
.trigger:hover {
    color: #888;
}

h3 {
    font-size: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 700;
}
.logo {
    height: 80px;
    margin: 16px;
    text-align: center;
}

.site-layout .site-layout-header {
    background: #fff;
}
.site-layout-header  {
    box-shadow: 0 1px 5px rgba(57, 63, 72, 0.1);
}
